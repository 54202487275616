import React, { Component, Fragment } from "react";
import { Col, FormGroup, Input } from "reactstrap";
import "./asesorias.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
 getManagerConsultancies,
 setCount,
} from "../../../redux/actions/consultanciesAction";
import Pagination from "react-js-pagination";
import { Button } from "reactstrap";
import Header from "../../header/Header";
import { Link } from "react-router-dom";

function CandidateRow(props) {
 const consultancies = props.consultancies;
 const path = props.path;
 var route =
  consultancies.consultanciesType == 0
   ? `new/employee/${consultancies.id}`
   : `new/jefe/${consultancies.id}`;
 return (
  <tr id="tr">
   <td className="titleItemRow">
    {consultancies.consultanciesType === 0
     ? consultancies.candidate
       ? consultancies.candidate.document
       : "N/A"
     : "N/A"}
   </td>
   <td className="titleItemRow">
    {consultancies.consultanciesType === 0
     ? consultancies.candidate
       ? consultancies.candidate.fullName
       : "N/A"
     : consultancies.immediateBoss}
   </td>
   <td className="titleItemRow">
    {consultancies.consultanciesType === 0 ? "Colaborador" : "Jefe inmediato"}
   </td>
   <td className="titleItemRow">{consultancies.theme}</td>
   <td className="titleItemRow">{consultancies.advisoryDate}</td>
   <td className="titleItemRow">
    <Link
     className="btnHV"
     to={`${route}`}>
     <i className="fas fa-eye text-info"></i>
    </Link>
   </td>
  </tr>
 );
}

class AsoseriasAcompanamiento extends Component {
 constructor(props) {
  super(props);
  this.state = {
   activePage: 1,
  };
 }

 componentDidMount = () => {
  sessionStorage.removeItem("name");
  this.props.setCount(0);
  if (this.props.allyData.length !== 0) {
   let managerId = this.props.allyData[0].managerData.id;
   this.props.getManagerConsultancies(managerId);
  }
 };

 componentDidUpdate = () => {};

 handlePageChange = (e) => {
  window.scroll(0, 0);
  let managerId = this.props.allyData[0].managerData.id;
  this.props.getManagerConsultancies(managerId, e);

  this.setState({
   activePage: e,
  });
 };

 changeSelectPage = (e) => {
  this.handlePageChange(e.target.value);
  this.setState({
   activePage: parseInt(e.target.value),
  });
 };

 renderOptionsPage = () => {
  let options = [];
  if (this.props.pageCount !== 0) {
   let base = 50;
   let pages = 0;
   pages = Math.ceil(this.props.pageCount / base);

   for (let i = 0; i < pages; i++) {
    options.push(i + 1);
   }
  }
  return (
   <FormGroup>
    <Input
     style={{ marginLeft: 10 }}
     type="select"
     value={this.state.activePage}
     onChange={this.changeSelectPage}>
     {options.map((i) => {
      return (
       <option
        key={i.toString()}
        value={i}>
        {i}
       </option>
      );
     })}
    </Input>
   </FormGroup>
  );
 };

 render() {
  return (
   <Fragment>
    <Header />
    <div className="col-12 bgResumenSeleccion">
     <div className="col-12">
      <div className="col-12 containerTitleSeleccion mb-5 text-left">
       <p>
        <strong className="titleCiudad text-left">Asesorias</strong>
       </p>
      </div>

      <div className="col-12 backgroundAsesorias">
       <div className="row containerHeaderAsesorias">
        <div className="col-10">
         <button className="btn btn-info">
          <Link
           to={`advisory/new`}
           style={{ textDecoration: "none" }}>
           <strong id="btnNew">Nuevo</strong>
          </Link>
         </button>
         <a
          href={`https://alianza.multimedialab.dev/UHJvamVjdA/5s48d4sa/`}
          target="_blank"
          style={{ display: "inline", marginLeft: 10 }}>
          <Button color="info">Descargar Consolidado Asesorias</Button>
         </a>
        </div>
        {/*  <div className="col-2">
                  <button className="btn btn-info">
                    <Link
                      to={`advisory/new/consolidado`}
                      style={{ textDecoration: "none" }}
                    >
                      <strong id="btnNew">Consolidado</strong>
                    </Link>
                  </button>
                </div> */}
       </div>

       <div className="table-responsiveAsesoria containerTable">
        <table className="table">
         <thead>
          <tr>
           <th
            scope="col"
            className="titleTable">
            Cédula
           </th>
           <th
            scope="col"
            className="titleTable">
            Nombre Completo
           </th>
           <th
            scope="col"
            className="titleTable">
            Tipo
           </th>
           <th
            scope="col"
            className="titleTable">
            Tema
           </th>
           <th
            scope="col"
            className="titleTable">
            Fecha
           </th>
           <th
            scope="col"
            className="titleTable"></th>
          </tr>
         </thead>
         <tbody>
          {this.props.consultanciesData2.length != 0
           ? this.props.consultanciesData2.results.map(
              (consultancies, index) => {
               return (
                <CandidateRow
                 consultancies={consultancies}
                 key={index.toString()}
                 path={this.props.modulePath}
                />
               );
              }
             )
           : null}
         </tbody>
        </table>
       </div>

       <Col
        md={12}
        style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
        <Pagination
         activePage={this.state.activePage}
         itemsCountPerPage={50}
         totalItemsCount={this.props.pageCount}
         pageRangeDisplayed={5}
         onChange={(e) => this.handlePageChange(e)}
         itemClass="page-item"
         linkClass="page-link"
         hideDisabled
         pageRangeDisplayed={5}
         pageRangeDisplayed={10}
        />
        {this.renderOptionsPage()}
       </Col>
      </div>
     </div>
    </div>
   </Fragment>
  );
 }
}

const mapStateToProps = (state) => {
 return {
  isLoading: state.candidateReducer.isLoading,
  consultanciesData2: state.consultanciesReducer.consultanciesData2,
  modulePath: state.allyReducer.modulePath,
  allyData: state.allyReducer.allyData,
  pageCount: state.consultanciesReducer.pageCount,
 };
};

const mapDispatchToProps = (dispatch) => {
 return {
  getManagerConsultancies: bindActionCreators(
   getManagerConsultancies,
   dispatch
  ),
  setCount: bindActionCreators(setCount, dispatch),
 };
};

export default connect(
 mapStateToProps,
 mapDispatchToProps
)(AsoseriasAcompanamiento);
