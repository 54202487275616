import React, { Component } from "react";
import {
 Card,
 CardBody,
 CardHeader,
 Col,
 Row,
 Form,
 CardFooter,
 TabContent,
 TabPane,
 Nav,
 NavItem,
 NavLink,
 Spinner,
 FormGroup,
 Input,
 Button,
 Modal,
 ModalHeader,
 ModalBody,
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
 getCandidateByID,
 update_Candidate,
 add_Candidate,
 get_all_agencies,
 get_all_programs,
 updatePositionsSubstates,
 setCandidate,
 addInfoContract,
 updateInfoContract,
} from "../../../redux/actions/candidateActions";
import "./viewHojaVida.css";
import { uploadSingleImage } from "../../../services/uploadFile";
import { getAllCities } from "../../../redux/actions/citiesActions";
import { getAllAllys } from "../../../redux/actions/allyActions";
import { getAllClient } from "../../../redux/actions/clientsActions";
import {
 getAllInterviewByID,
 getFindingsCandidate,
 createFinding,
 createInterviewState,
} from "../../../redux/actions/selectionActions";
import { getAllVacantes } from "../../../redux/actions/vacanteActions";
import {
 getInterviewsByCandidate,
 updateInterviewAnswered,
 createInterviewAnswered,
} from "../../../redux/actions/interviewActions";
import {
 getAllConsultanciesByCandidate,
 setIsSave,
} from "../../../redux/actions/consultanciesAction";
import {
 getTracingCandidate,
 getTracingCandidateNuevo,
 getWorkshopByUser,
} from "../../../redux/actions/acompanamientoActions";
import { getManagersList } from "../../../redux/actions/managersActions";
import {
 addTraceability,
 getCandidateTrace,
} from "../../../redux/actions/traceabilityActions";
import SelectSearch from "react-select-search";
import classnames from "classnames";
import swal from "sweetalert";
import axios from "axios";
import urls from "../../../apiConf";

class ViewHojaVida extends Component {
 constructor(props) {
  super(props);
  console.log("hoja vida");
  this.state = {
   assistance: [],
   activeTab: "1",
   admissionDate: new Date(),
   birthDate: "",
   age: null,
   fullName: "",
   document: "",
   militaryCard: "",
   phone: "",
   email: "",
   gender: "",
   cellphone: "",
   cellphoneTwo: "",
   scholarship: "",
   arnStatus: "",
   career: "",
   civilStatus: "S/I",
   currentlyStudying: "false",
   whatStudies: "",
   city: "",
   direction: "",
   neighborhood: "",
   commune: "",
   program: null,
   arnPoblation: "",
   group: "",
   profilePicture: {},
   candidateState: 0,
   candidateSubstate: 0,
   prevSubstate: 0,
   personalPresentation: false,
   emotionalManagement: false,
   nameBussiness: "",
   findings: false,
   txtFindings: null,
   documentationFiles: [],
   idProgram: "",
   slcPersonalPresentation: "",
   slcEmotionalManagement: "",
   slcFindings: "no",
   disableFindings: true,
   disableMilitaryCard: false,
   retirementReason: "",
   retirementRealReason: "",
   retirementTimestamp: "",
   positions: [
    {
     positionName: "",
     companyApply: "",
    },
   ],
   documentArr: [
    {
     docName: "",
     path: [],
    },
   ],
   imagePath: [],
   documents: "",
   whoIs: 0,
   findingsAlianza: "",
   findingsAlianzaConcepts: "",
   candidateAnswerInterview: 0,
   interviewer: " ",
   dateInterview: "",
   hourInterview: "",
   dateInterviewC: "",
   hourInterviewC: "",
   tattoInterview: "",
   concepts: "",
   inductionInterview: "",
   dateInterviewSel: "",
   conceptsHa: "",
   contractType: "",
   contractTimestamp: "",
   selectedVacancy: "",
   selectCompany: "",
   selectPosition: 0,
   wasSent: "no",
   place: "",
   contractDate: "",
   contractType: "",
   immediateBoss: "",
   induction: "",
   medicalExam: "",
   courses: "",
   references: "",
   retirementDate: "",
   findingsContent: "no",
   txtFindingsContent: "",
   reason: "",
   reasonDetail: "",
   optionsFindings: [
    {
     name: "Motivos",
     type: "group",
     items: [
      {
       value: "No le interesa",
       name: "No le interesa",
      },
      {
       value: "No cumple con el perfil",
       name: "No cumple con el perfil",
      },
      {
       value: "Actitud",
       name: "Actitud",
      },
      {
       value: "Inasistencia",
       name: "Inasistencia",
      },
      {
       value: "No citado",
       name: "No citado",
      },
      {
       value: "Muerte",
       name: "Muerte",
      },
      {
       value: "No contactado",
       name: "No contactado",
      },
      {
       value: "Numero errado",
       name: "Número errado",
      },
      {
       value: "N/A",
       name: "No Aplica",
      },
     ],
    },
    {
     name: "Hallazgos",
     type: "group",
     items: [
      {
       value: "Si",
       name: "Si",
      },
      {
       value: "No",
       name: "No",
      },
      {
       value: "N/A",
       name: "No Aplica",
      },
      {
       value: "Juridicos",
       name: "Jurídicos",
      },
      {
       value: "Presentacion Personal",
       name: "Presentación Personal",
      },
      {
       value: "Manejo Emocional",
       name: "Manejo Emocional",
      },
     ],
    },
   ],
   openModal: false,
   tracingDetail: {
    year: "",
    adaptationCohesion: 0,
    communication: 0,
    dispositionTask: 0,
    month: 0,
    observations: "",
    personalPresentation: 0,
    regulationRules: 0,
    relationships: 0,
    total: 0,
   },
   tracingDetailNuevo: {
    year: "",
    calidad: 0,
    proactividad: 0,
    responsabilidad: 0,
    disposicion: 0,
    puntualidad: 0,
    relaciones: 0,
    cumplimiento: 0,
    presentacion: 0,
    honestidad: 0,
    habilidad: 0,
    observations: "",

    total: 0,
   },
   years: [],
  };
 }

 componentDidMount = async () => {
  this.props.getAllCities();
  this.props.getAllAllys();
  this.props.getAllClient();
  this.props.get_all_agencies();
  this.props.get_all_programs();
  this.props.getAllVacantes();

  var candidateId = this.props.match.params.candidate;
  await this.props.getAllInterviewByID(candidateId, 0);
  await this.props.getCandidateByID(candidateId);
  this.props.getFindingsCandidate(candidateId);
  this.setInitialData();
  this.getListYears();
 };

 setInitialData = () => {
  try {
   var findCandidate = this.props.candidate;
   console.log(findCandidate);
   var findInterview = this.props.interviewByID;
   var allyType = 0;

   this.setState({
    admissionDate: findCandidate.admissionDate,
    birthDate: findCandidate.birthDate,
    age: findCandidate.age,
    fullName: findCandidate.fullName,
    document: findCandidate.document,
    militaryCard: findCandidate.militaryCard,
    phone: findCandidate.phone,
    email: findCandidate.email,
    gender: findCandidate.gender,
    cellphone: findCandidate.cellphone,
    cellphoneTwo: findCandidate.cellphoneTwo,
    scholarship: findCandidate.scholarship,
    arnStatus: findCandidate.arnStatus,
    arnPoblation: findCandidate.arnPoblation,
    career: findCandidate.career,
    civilStatus: findCandidate.civilStatus,
    currentlyStudying:
     findCandidate.currentlyStudying === false ? "false" : "true",
    whatStudies: findCandidate.whatStudies,
    city: findCandidate.idCity,
    direction: findCandidate.direction,
    neighborhood: findCandidate.neighborhood,
    commune: findCandidate.commune,
    program: findCandidate.program,
    profilePicture: findCandidate.profilePicture,
    candidateState: findCandidate.positions[0]?.proccessState,
    candidateSubstate: findCandidate.positions[0]?.proccessSubstate,
    prevSubstate: findCandidate.positions[0]?.proccessSubstate,
    nameBussiness: findCandidate.positions[0]?.nameBussiness,
    personalPresentation: findCandidate.personalPresentation,
    emotionalManagement: findCandidate.emotionalManagement,
    findings: findCandidate.findings,
    txtFindings: findCandidate.txtFindings,
    retirementReason: findCandidate.retirementReason,
    retirementRealReason: findCandidate.retirementRealReason,
    retirementTimestamp: findCandidate.retirementTimestamp,
    documentationFiles:
     findCandidate.documentationFiles !== null
      ? findCandidate.documentationFiles !== "null"
        ? JSON.parse(findCandidate.documentationFiles)
        : []
      : [],
    idProgram: findCandidate.idProgram,
    slcPersonalPresentation:
     findCandidate.personalPresentation == true ? "cumple" : "no",
    slcEmotionalManagement:
     findCandidate.emotionalManagement == true ? "cumple" : "no",
    slcFindings: "no",
    disableFindings: true,
    disableMilitaryCard: false,
    positions: findCandidate.positions,
    documentArr: [],
    imagePath: [],
    documents: "",
    whoIs: allyType,
    findingsAlianza: "",
    findingsAlianzaConcepts: "",
    candidateAnswerInterview: 0,
    interviewer: " ",
    dateInterview: "",
    hourInterview: "",
    dateInterviewC: "",
    hourInterviewC: "",
    tattoInterview: "",
    concepts: "",
    group: findCandidate.group === null ? "" : findCandidate.group,
    inductionInterview: "",
    dateInterviewSel: "",
    conceptsHa: "",
    contractType: findCandidate.contractType,
    contractTimestamp: findCandidate.contractTimestamp,
    selectedVacancy: findCandidate.positions[0].idVacancy,
    selectCompany: findCandidate.positions[0].nameClient,
    selectPosition: findCandidate.positions[0].id,
    wasSent:
     findCandidate.contract.length !== 0
      ? findCandidate.contract[0].wasSent === true
        ? "si"
        : "no"
      : "no",
    place:
     findCandidate.contract.length !== 0 ? findCandidate.contract[0].place : "",
    contractDate:
     findCandidate.contract.length !== 0
      ? findCandidate.contract[0].contractDate
      : "",
    contractType:
     findCandidate.contract.length !== 0
      ? findCandidate.contract[0].contractType
      : "",
    immediateBoss:
     findCandidate.contract.length !== 0
      ? findCandidate.contract[0].immediateBoss
      : "",
    induction:
     findCandidate.contract.length !== 0
      ? findCandidate.contract[0].induction === true
        ? "si"
        : "no"
      : "no",
    medicalExam:
     findCandidate.contract.length !== 0
      ? findCandidate.contract[0].medicalExam === true
        ? "si"
        : "no"
      : "no",
    courses:
     findCandidate.contract.length !== 0
      ? findCandidate.contract[0].courses === true
        ? "si"
        : "no"
      : "no",
    references:
     findCandidate.contract.length !== 0
      ? findCandidate.contract[0].references === true
        ? "si"
        : "no"
      : "no",
    retirementDate:
     findCandidate.contract.length !== 0
      ? findCandidate.contract[0].retirementDate
      : "",
    reason:
     findCandidate.contract.length !== 0
      ? findCandidate.contract[0].reason
      : "",
    reasonDetail:
     findCandidate.contract.length !== 0
      ? findCandidate.contract[0].reasonDetail
      : "",
    findingsContent: findCandidate.findingsContent ? "si" : "no",
    txtFindingsContent: findCandidate.txtFindingsContent,
   });
   console.log(this.state)
  } catch (err) {
   console.log("error", err);
  }
 };

 handleSelect = (e) => {
  this.setState({ slcPersonalPresentation: e.target.value });
 };

 handleSelect2 = (e) => {
  this.setState({ slcEmotionalManagement: e.target.value });
 };

 handleSelect3 = (e) => {
  this.setState({ slcFindings: e.target.value });
  if (this.state.disableFindings) {
   this.setState({ disableFindings: false });
  } else {
   this.setState({ disableFindings: true });
  }
 };

 handleChange = (e) => {
  
  const newState = this.state;
  newState[e.target.id] = e.target.value;
  this.setState(newState);

  if (this.state.gender === "F") {
   this.setState({ disableMilitaryCard: true, militaryCard: 0 });
  } else {
   this.setState({ disableMilitaryCard: false });
  }

  if (e.target.id === "slcFindings") {
    this.setState({ findingsContent: e.target.value });
  }

  

  if (e.target.id === "selectedVacancy") {
   let findP = this.props.candidate.positions.find(
    (el) => el.idVacancy === e.target.value
   );
   this.setState({
    selectCompany: findP.newName,
    selectPosition: findP.id,
   });
  }
 };

 handleChangeGroup = (e) => {
  this.setState({
   findingsAlianza: e,
  });
 };

 handleChangePositionsObj = (e, key, i) => {
  const newState = this.state;
  newState[e.target.id][i][key] = e.target.value;
  this.setState(newState);
 };

 handleChangeFile = (e) => {
  if (e.target.files[0]) {
   const imageFile = e.target.files[0];
   this.setState({
    imagePath: imageFile,
   });
  }
 };

 handleChangeMultiFile = (e, key, i) => {
  if (e.target.files[0]) {
   const imageFile = e.target.files[0];
   this.state.documentArr[i][key] = imageFile;
  }
 };

 addDocuments = () => {
  var tempDocs = this.state.documentArr;
  tempDocs.push({
   docName: "",
   path: [],
  });
  this.setState({
   documentArr: tempDocs,
  });
 };

 rmDocuments = (item, index) => {
  var tempDocs = this.state.documentArr;
  var i = tempDocs.indexOf(item);
  if (i !== -1) {
   tempDocs.splice(i, 1);
   this.setState({
    documentArr: tempDocs,
   });
  }
 };

 addPositions = () => {
  var tempPosition = this.state.positions;
  tempPosition.push({
   positionName: "",
   companyApply: "",
  });
  this.setState({
   positions: tempPosition,
  });
 };

 rmPositions = (item, index) => {
  if (this.state.positions.length > 1) {
   let tempPositions = this.state.positions;
   let i = tempPositions.indexOf(item);
   if (i !== -1) {
    tempPositions.splice(i, 1);
    this.setState({
     Positions: tempPositions,
    });
   }
  }
 };

 addFinding = () => {
  var candidateId = this.props.match.params.candidate;
  if (
   this.state.findingsAlianzaConcepts !== "" &&
   this.state.findingsAlianza !== ""
  ) {
   let obj = {
    findingType: this.state.findingsAlianza,
    reasonFindings: this.state.findingsAlianzaConcepts,
    idCandidate: this.props.candidate.url,
   };
   this.props.createFinding(obj, candidateId);
   this.setState({
    findingsAlianza: "",
    findingsAlianzaConcepts: "",
   });
  } else {
   swal("Por favor indica el hallazgo / motivo y la causa real.");
  }
 };

 updateDataCandidate = () => {
  console.log(this.state.slcFindings);
  var objCandidate = {
   age: this.calculateAge(this.state.birthDate),
   group: this.state.group !== "" ? this.state.group : null,
   fullName: this.state.fullName,
   phone: this.state.phone,
   email: this.state.email,
   gender: this.state.gender,
   cellphone: this.state.cellphone,
   cellphoneTwo: this.state.cellphoneTwo,
   scholarship: this.state.scholarship,
   direction: this.state.direction,
   neighborhood: this.state.neighborhood,
   commune: this.state.commune,
   civilStatus: this.state.civilStatus,
   currentlyStudying: this.state.currentlyStudying === "false" ? false : true,
   whatStudies: this.state.whatStudies,
   career: this.state.career,
   birthDate: this.state.birthDate,
   idCity: this.state.city,
   txtFindingsContent: this.state.txtFindingsContent,
   findingsContent: this.state.slcFindings === "no" ? false : true,
  };
  let obj_relacionamiento = {
   position: this.state.selectPosition,
   proccessSubstate: this.state.candidateSubstate,
   idCandidate: this.props.candidate.url,
  };
  let interviewObj = {
   answered: this.state.candidateAnswerInterview === "si" ? true : false,
   interviewer: this.state.interviewer,
   dateInterview: this.state.dateInterview,
   hourInterview: this.state.hourInterview,
   tattooInterview: this.state.tattoInterview === "si" ? true : false,
   concepts: this.state.concepts !== "" ? this.state.concepts : null,
   idCandidate: this.props.candidate.url,
  };
  let contractObj = {
   wasSent: this.state.wasSent === "si" ? true : false,
   place: this.state.place,
   contractDate: this.state.contractDate,
   contractType: this.state.contractType,
   immediateBoss: this.state.immediateBoss,
   induction: this.state.induction === "si" ? true : false,
   medicalExam: this.state.medicalExam === "si" ? true : false,
   courses: this.state.courses === "si" ? true : false,
   references: this.state.references === "si" ? true : false,
   retirementDate: this.state.retirementDate,
   reason: this.state.reason,
   reasonDetail: this.state.reasonDetail,
   candidate: this.props.candidate.url,
  };

  swal({
   title: "Importante!",
   text: "Estas seguro de actualizar la información del candidato?",
   icon: "warning",
   buttons: true,
   // dangerMode: true
  }).then((willSave) => {
   if (willSave) {
    this.props
     .update_Candidate(this.props.match.params.candidate, objCandidate)
     .then(() => {
      if (this.state.activeTab === "1") {
       swal({
        icon: "success",
        text: "Los datos se actualizaron correctamente!",
       });
      }
     });
    if (this.state.prevSubstate !== this.state.candidateSubstate) {
     this.validateStateTrace(obj_relacionamiento);
    }
    if (this.state.activeTab === "6") {
     if (this.props.interviewList.length !== 0) {
      this.props.updateInterviewAnswered(
       this.props.interviewList.results[0].id,
       interviewObj
      );
     } else {
      this.props.createInterviewAnswered(interviewObj);
     }
    } else if (this.state.activeTab === "7") {
     if (this.props.candidate.contract.length !== 0) {
      let id = this.props.candidate.contract[0].id;
      this.props.updateInfoContract(id, contractObj);
     } else {
      this.props.addInfoContract(contractObj);
     }
    } else if (this.state.activeTab === "4") {
     if (
      this.state.documentArr.length !== 0 &&
      this.state.documentArr[0].docName !== ""
     ) {
      var documents = [];
      this.state.documentArr.forEach(async (item, index) => {
       var data = await uploadSingleImage(item.path);
       documents.push({
        docName: item.docName,
        url: data.file,
       });

       if (this.state.documentArr.length === documents.length) {
        let tempDocument = this.state.documentationFiles;
        tempDocument.push(...documents);
        this.setState({
         documentationFiles: tempDocument,
         documentArr: [],
        });
        this.props.update_Candidate(this.props.match.params.candidate, {
         documentationFiles: JSON.stringify(tempDocument),
        });
       }
      });
     }
    }
   }
  });
 };

 confirmSave = () => {
  swal("El candidato se guardo correctamente.");
  this.setInitialData();
 };

 calculateAge = (birth) => {
  var today = new Date();
  var birthDate = new Date(birth);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
   age--;
  }
  return age;
 };

 formatDate(date) {
  var d = new Date(date),
   month = "" + (d.getMonth() + 1),
   day = "" + d.getDate(),
   year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
 }

 toggleTab = async (index) => {
  var candidateId = this.props.match.params.candidate;
  this.setState({
   activeTab: index,
  });
  switch (index) {
   case "6":
    await this.props.getInterviewsByCandidate(candidateId, 0);
    await this.props.getManagersList();
    if (this.props.interviewList.length !== 0) {
     const data = this.props.interviewList.results[0];
     this.setState({
      candidateAnswerInterview: data.answered === true ? "si" : "no",
      interviewer: data.interviewer ? data.interviewer : "",
      dateInterview: data.dateInterview,
      hourInterview: data.hourInterview,
      tattoInterview: data.tattooInterview === true ? "si" : "no",
      concepts: data.concepts,
     });
    } else {
     this.setState({
      interviewer: "",
     });
    }
    break;
   case "7":
    await this.props.getInterviewsByCandidate(candidateId, 1);
    if (this.props.interviewList.length !== 0) {
     const data = this.props.interviewList.results[0];
     this.setState({
      dateInterviewC: data.dateInterview,
      hourInterviewC: data.hourInterview,
     });
    }
    break;
   case "8":
    await this.props.getAllConsultanciesByCandidate(candidateId).then(() => {
     this.props.setIsSave(false);
    });
    break;
   case "9":
    await this.props.getTracingCandidate(candidateId);
    await this.props.getTracingCandidateNuevo(candidateId);
    break;
   case "10":
    await this.props.getWorkshopByUser(this.state.group);
    const reponseToken = await axios.post(
     `${urls.url_base}/dG9rZW4/`,
     urls.body,
     {
      headers: { "Content-Type": "application/json" },
     }
    );
    //console.log(candidateId)
    const token = await reponseToken.data.token;
    const responseAssistance = await axios.get(
     `${urls.url_base}/YXNzaXM/?candidate=${candidateId}`,
     { headers: { Authorization: `JWT ${token}` } }
    );
    const data = await responseAssistance.data;
    this.setState({
     assistance: data.results,
    });
    //console.log("asistencia")
    //console.log(data)
    break;
   case "11":
    await this.props.getCandidateTrace(candidateId);
    break;
   default:
    break;
  }
 };

 validateStateTrace = (obj_relacionamiento) => {
  var traceObj = {
   action: "",
   candidate: this.props.candidate.url,
   manager: this.validateIdentity().url,
  };
  switch (this.state.candidateSubstate) {
   case "1":
    traceObj.action = `Cambio estado a Remitidos por ${
     this.validateIdentity().name
    }`;
    break;
   case "2":
    traceObj.action = `Cambio estado a Entrevista alianza por ${
     this.validateIdentity().name
    }`;
    break;
   case "3":
    traceObj.action = `Cambio estado a No citados por ${
     this.validateIdentity().name
    }`;
    break;
   case "4":
    traceObj.action = `Cambio estado a No asistieron por ${
     this.validateIdentity().name
    }`;
    break;
   case "5":
    traceObj.action = `Cambio estado a No continuan proceso por ${
     this.validateIdentity().name
    }`;
    break;
   case "6":
    traceObj.action = `Cambio estado a No pasaron proceso por ${
     this.validateIdentity().name
    }`;
    break;
   case "7":
    traceObj.action = `Cambio estado a Pendientes por ${
     this.validateIdentity().name
    }`;
    break;
   case "8":
    traceObj.action = `Cambio estado a Aprobador por la Alianza por ${
     this.validateIdentity().name
    }`;
    break;
   case "9":
    traceObj.action = `Cambio estado a Entrevista cliente por ${
     this.validateIdentity().name
    }`;
    break;
   case "10":
    traceObj.action = `Cambio estado a Pendientes de contratación por ${
     this.validateIdentity().name
    }`;
    break;
   case "11":
    traceObj.action = `Cambio estado a Vinculados por ${
     this.validateIdentity().name
    }`;
    break;
   case "12":
    traceObj.action = `Cambio estado a Vinculados aprendiz por ${
     this.validateIdentity().name
    }`;
    break;
   case "13":
    traceObj.action = `Cambio estado a Vinculados reintegro por ${
     this.validateIdentity().name
    }`;
    break;
   case "14":
    traceObj.action = `Cambio estado a Desvinculados por ${
     this.validateIdentity().name
    }`;
    break;
   default:
    traceObj.action = `Cambio estado Nuevo por ${this.validateIdentity().name}`;
    break;
  }
  this.props.updatePositionsSubstates(obj_relacionamiento, true, traceObj);
 };

 validateIdentity = () => {
  if (this.props.allyData.length !== 0) {
   let obj = {};
   let data = this.props.allyData[0];
   if (data.businessData) {
    obj = {
     name: data.businessData.name,
     url: data.businessData.url,
    };
   } else if (data.advisorData) {
    obj = {
     name: data.advisorData.fullName,
     url: data.advisorData.url,
    };
   } else if (data.managerData) {
    obj = {
     name: data.managerData.fullName,
     url: data.managerData.url,
    };
   }
   return obj;
  }
 };

 newAdvisory = () => {
  this.props.setCandidate([]);
  sessionStorage.setItem("name", this.state.fullName);
  this.props.history.push(`/${this.props.modulePath}/advisory/new/employee`);
 };

 newWorkshop = () => {
  this.props.setCandidate([]);
  this.props.history.push(`/${this.props.modulePath}/talleres/new`);
 };

 validateColor = () => {
  return "bgColor3";
 };

 toggleModal = (item) => {
  this.setState({
   openModal: !this.state.openModal,
   tracingDetail: item,
   tracingDetailNuevo: item,
  });
 };
 renderTracingNuevoModal = () => {
  return (
   <Modal
    isOpen={this.state.openModal}
    toggle={this.closeClean}
    size="md">
    <ModalHeader toggle={() => this.toggleModal("")}>
     Detalle seguimiento
    </ModalHeader>
    <ModalBody>
     <p style={{ marginBottom: 0 }}>
      <b>Año</b>: {this.state.tracingDetail.year}
     </p>
     <p style={{ marginBottom: 0 }}>
      <b>Mes</b>: {this.state.tracingDetail.month}
     </p>
     <p style={{ marginBottom: 0 }}>
      <b>Total</b>: {this.state.tracingDetail.total}
     </p>
     <ul className="detailTracing">
      <li>
       <span>Calidad en el trabajo:</span>
       <span>{this.state.tracingDetail.calidad}</span>
      </li>
      <li>
       <span>Proactividad:</span>
       <span>{this.state.tracingDetail.proactividad}</span>
      </li>
      <li>
       <span>Responsabilidad:</span>
       <span>{this.state.tracingDetail.responsabilidad}</span>
      </li>
      <li>
       <span>Disposición a la tarea y colaboración:</span>
       <span>{this.state.tracingDetail.disposicion}</span>
      </li>
      <li>
       <span>Puntualidad:</span>
       <span>{this.state.tracingDetail.puntualidad}</span>
      </li>
      <li>
       <span>Relaciones interpersonales:</span>
       <span>{this.state.tracingDetail.relaciones}</span>
      </li>
      <li>
       <span>Cumplimiento de normas y reglamento interno:</span>
       <span>{this.state.tracingDetail.cumplimiento}</span>
      </li>
      <li>
       <span>Presentación personal:</span>
       <span>{this.state.tracingDetail.presentacion}</span>
      </li>
      <li>
       <span>Honestidad:</span>
       <span>{this.state.tracingDetail.honestidad}</span>
      </li>
      <li>
       <span>Habilidad técnica:</span>
       <span>{this.state.tracingDetail.habilidad}</span>
      </li>
      <li>Observaciones: {this.state.tracingDetail.observations}</li>
     </ul>
    </ModalBody>
   </Modal>
  );
 };

 renderTracingModal = () => {
  return (
   <Modal
    isOpen={this.state.openModal}
    toggle={this.closeClean}
    size="md">
    <ModalHeader toggle={() => this.toggleModal("")}>
     Detalle seguimiento
    </ModalHeader>
    <ModalBody>
     <p style={{ marginBottom: 0 }}>
      <b>Año</b>: {this.state.tracingDetail.year}
     </p>
     <p style={{ marginBottom: 0 }}>
      <b>Mes</b>: {this.state.tracingDetail.month}
     </p>
     <p style={{ marginBottom: 0 }}>
      <b>Total</b>: {this.state.tracingDetail.total}
     </p>
     <ul className="detailTracing">
      <li>
       <span>Adaptación y cohesión al equipo de trabajo:</span>
       <span>{this.state.tracingDetail.adaptationCohesion}</span>
      </li>
      <li>
       <span>Cumplimiento de normas y reglamentos:</span>
       <span>{this.state.tracingDetail.regulationRules}</span>
      </li>
      <li>
       <span>Relaciones interpersonales:</span>
       <span>{this.state.tracingDetail.relationships}</span>
      </li>
      <li>
       <span>Presentación personal:</span>
       <span>{this.state.tracingDetail.personalPresentation}</span>
      </li>
      <li>
       <span>Comunicación:</span>
       <span>{this.state.tracingDetail.communication}</span>
      </li>
      <li>
       <span>Disposición a la tarea:</span>
       <span>{this.state.tracingDetail.dispositionTask}</span>
      </li>
      <li>Observaciones: {this.state.tracingDetail.observations}</li>
     </ul>
    </ModalBody>
   </Modal>
  );
 };

 getListYears = () => {
  let currentYear = new Date().getFullYear();
  let tempYears = [currentYear];
  var prevYear = new Date();
  for (let i = 0; i < 5; i++) {
   prevYear.setFullYear(prevYear.getFullYear() - 1);
   let year = prevYear.getFullYear();
   tempYears.push(year);
   this.setState({
    years: tempYears,
   });
  }
 };

 filterByYear = (e) => {
  console.log(e.target.value);
 };

 render() {
  if (this.props.candidate.length !== 0) {
   return (
    <div
     className="backgroundHojaVida"
     style={{ marginTop: "5%" }}>
     <Row style={{ width: "100%", justifyContent: "center" }}>
      <Col
       xs="12"
       md="12">
       <Card>
        <CardHeader>
         <strong>HOJA DE VIDA</strong>
        </CardHeader>
        <CardBody style={{ overflowY: "scroll" }}>
         <Row style={{ marginBottom: 15 }}>
          <Col>
           <div className="form-group row">
            <label className="col-sm-10 col-form-label">
             Vacante a postular
            </label>
            <div className="col-sm-12">
             <select
              className="form-control"
              onChange={this.handleChange}
              id="selectedVacancy"
              value={this.state.selectedVacancy}>
              <option
               selected
               value=""
               disabled>
               Seleccione la vacante
              </option>
              {this.props.candidate.positions.map((item, index) => {
               return (
                <option
                 key={index.toString()}
                 value={item.idVacancy}>
                 {item.nameVacancy}
                </option>
               );
              })}
             </select>
            </div>
           </div>
          </Col>
          <Col>
           <div className="form-group row">
            <label className="col-10 col-form-label">Empresa a postular</label>
            <div className="col-sm-12">
             <input
              type="text"
              className="form-control"
              onChange={this.handleChange}
              id="selectCompany"
              value={this.state.selectCompany}
              disabled
             />
            </div>
           </div>
          </Col>
          <Col>
           <div className="form-group row">
            <label className="col-10 col-form-label">Estado</label>
            <div className="col-sm-12">
             <select
              className="form-control"
              id="candidateSubstate"
              onChange={this.handleChange}
              value={this.state.candidateSubstate}>
              <option value="0">Nuevo</option>
              <option value="1">Remitidos</option>
              <option value="2">Entrevista alianza</option>
              <option value="3">No citados</option>
              <option value="4">No asistieron</option>
              <option value="5">No continuan proceso</option>
              <option value="6">No pasaron proceso</option>
              <option value="7">Pendientes</option>
              <option value="8">Aprobados por la alianza</option>
              <option value="9">Entrevista cliente</option>
              <option value="10">Pendientes de contratación</option>
              <option value="11">Vinculados</option>
              <option value="12">Vinculados aprendiz</option>
              <option value="13">Vinculados reintegro</option>
              <option value="14">Desvinculados</option>
              <option value="15">No pasa proceso con la empresa</option>
             </select>
            </div>
           </div>
          </Col>
         </Row>
         <Nav tabs>
          {this.props.visibleTabs.includes("1") && (
           <NavItem>
            <NavLink
             className={classnames({
              active: this.state.activeTab === "1",
             })}
             onClick={() => {
              this.toggleTab("1");
             }}>
             Información general
            </NavLink>
           </NavItem>
          )}
          {this.props.visibleTabs.includes("4") && (
           <NavItem>
            <NavLink
             className={classnames({
              active: this.state.activeTab === "4",
             })}
             onClick={() => {
              this.toggleTab("4");
             }}>
             Documentos
            </NavLink>
           </NavItem>
          )}
          {/* {this.props.visibleTabs.includes('2') && <NavItem>
                          <NavLink
                            className={classnames({ active: this.state.activeTab === '2' })}
                            onClick={() => {this.toggleTab('2')}}
                          >
                            Cargos a postular
                          </NavLink>
                        </NavItem>} */}
          {this.props.visibleTabs.includes("3") && (
           <NavItem>
            <NavLink
             className={classnames({
              active: this.state.activeTab === "3",
             })}
             onClick={() => {
              this.toggleTab("3");
             }}>
             Características
            </NavLink>
           </NavItem>
          )}
          {this.props.visibleTabs.includes("5") && (
           <NavItem>
            <NavLink
             className={classnames({
              active: this.state.activeTab === "5",
             })}
             onClick={() => {
              this.toggleTab("5");
             }}>
             Hallazgos / Motivos
            </NavLink>
           </NavItem>
          )}
          {this.props.visibleTabs.includes("6") && (
           <NavItem>
            <NavLink
             className={classnames({
              active: this.state.activeTab === "6",
             })}
             onClick={() => {
              this.toggleTab("6");
             }}>
             Entrevistas
            </NavLink>
           </NavItem>
          )}

          {this.props.visibleTabs.includes("7") && (
           <NavItem>
            <NavLink
             className={classnames({
              active: this.state.activeTab === "7",
             })}
             onClick={() => {
              this.toggleTab("7");
             }}>
             Contratación
            </NavLink>
           </NavItem>
          )}
          {this.props.visibleTabs.includes("8") && (
           <NavItem>
            <NavLink
             className={classnames({
              active: this.state.activeTab === "8",
             })}
             onClick={() => {
              this.toggleTab("8");
             }}>
             Asesorías
            </NavLink>
           </NavItem>
          )}
          {this.props.visibleTabs.includes("9") && (
           <NavItem>
            <NavLink
             className={classnames({
              active: this.state.activeTab === "9",
             })}
             onClick={() => {
              this.toggleTab("9");
             }}>
             Seguimientos
            </NavLink>
           </NavItem>
          )}
          {this.props.visibleTabs.includes("10") && (
           <NavItem>
            <NavLink
             className={classnames({
              active: this.state.activeTab === "10",
             })}
             onClick={() => {
              this.toggleTab("10");
             }}>
             Talleres
            </NavLink>
           </NavItem>
          )}
          {this.props.visibleTabs.includes("11") && (
           <NavItem>
            <NavLink
             className={classnames({
              active: this.state.activeTab === "11",
             })}
             onClick={() => {
              this.toggleTab("11");
             }}>
             Trazabilidad
            </NavLink>
           </NavItem>
          )}
          {this.props.visibleTabs.includes("11") && (
           <NavItem>
            <NavLink
             className={classnames({
              active: this.state.activeTab === "12",
             })}
             onClick={() => {
              this.toggleTab("12");
             }}>
             Retiros
            </NavLink>
           </NavItem>
          )}
          {this.props.visibleTabs.includes("11") && (
           <NavItem>
            <NavLink
             className={classnames({
              active: this.state.activeTab === "13",
             })}
             onClick={() => {
              this.toggleTab("13");
             }}>
             Pruebas psicométricas
            </NavLink>
           </NavItem>
          )}
         </Nav>
         <Form className="form-horizontal">
          <TabContent activeTab={this.state.activeTab}>
           <TabPane tabId="1">
            <br />
            <Row>
             <Col
              xs="12"
              md="6">
              <div className="form-group row containerFoto">
               <label className="col-12 col-form-label">Foto Perfil</label>
               <div className="col-12 containerFile">
                <input
                 type="file"
                 accept="image/x-png,image/jpeg"
                 className="form-control-file"
                 disabled
                 id="profilePicture"
                 onChange={this.handleChangeFile}
                />
               </div>
              </div>
             </Col>
             <Col
              xs="12"
              md="6">
              {this.state.profilePicture !== null && (
               <div
                className="col-sm-2"
                style={{ marginTop: 15 }}>
                <img
                 src={this.state.profilePicture}
                 width="150px"
                 height="150px"
                />
               </div>
              )}
             </Col>
            </Row>
            <Row>
             <Col
              xs="12"
              md="6">
              <div className="form-group row">
               <label className="col-12 col-form-label">Nombre Completo</label>
               <div className="col-sm-10">
                <input
                 type="text"
                 className="form-control"
                 placeholder="Ingresa tu nombre completo"
                 id="fullName"
                 value={this.state.fullName}
                 onChange={this.handleChange}
                />
               </div>
              </div>
              <div className="form-group row">
               <label className="col-12 col-form-label">Genero</label>
               <div className="col-sm-10">
                <select
                 className="form-control"
                 id="gender"
                 value={this.state.gender}
                 onChange={this.handleChange}>
                 <option
                  value=""
                  selected
                  disabled>
                  Seleccione el genero
                 </option>
                 <option value="M">Masculino</option>
                 <option value="F">Femenino</option>
                 <option value="No">No binario</option>
                </select>
               </div>
              </div>
              <div className="form-group row">
               <label className="col-12 col-form-label">Telefono Fijo</label>
               <div className="col-sm-10">
                <input
                 type="text"
                 className="form-control"
                 placeholder="Ingresa un telefono fijo"
                 id="phone"
                 value={this.state.phone}
                 onChange={this.handleChange}
                />
               </div>
              </div>
              <div className="form-group row">
               <label className="col-12 col-form-label">Cedula</label>
               <div className="col-sm-10">
                <input
                 type="text"
                 className="form-control"
                 placeholder="Ingresa tu numero de identificación"
                 id="document"
                 value={this.state.document}
                 disabled
                 onChange={this.handleChange}
                />
               </div>
              </div>
              <div className="form-group row">
               <label className="col-12 col-form-label">Libreta Militar</label>
               <div className="col-sm-10">
                <input
                 type="text"
                 className="form-control"
                 placeholder="Ingresa tu numero de libreta militar"
                 id="militaryCard"
                 value={this.state.militaryCard}
                 disabled
                 onChange={this.handleChange}
                />
               </div>
              </div>
              <div className="form-group row">
               <label className="col-12 col-form-label">Celular</label>
               <div className="col-sm-10">
                <input
                 type="text"
                 className="form-control"
                 placeholder="Ingresa tu numero de celular"
                 id="cellphone"
                 value={this.state.cellphone}
                 onChange={this.handleChange}
                />
               </div>
              </div>
              <div className="form-group row">
               <label className="col-12 col-form-label">Celular 2</label>
               <div className="col-sm-10">
                <input
                 type="text"
                 className="form-control"
                 placeholder="Ingresa otro numero de celular"
                 id="cellphoneTwo"
                 value={this.state.cellphoneTwo}
                 onChange={this.handleChange}
                />
               </div>
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">Estado Civil</label>
               <div className="col-sm-10">
                <Input
                 type="select"
                 className="form-control"
                 placeholder="Selecciona el estado civil"
                 id="civilStatus"
                 value={this.state.civilStatus}
                 onChange={this.handleChange}>
                 <option>S/I</option>
                 <option>Soltero</option>
                 <option>Casado</option>
                 <option>Unión libre</option>
                 <option>Viudo</option>
                 <option>Separado</option>
                 <option>Divorciado</option>
                </Input>
               </div>
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">Formación</label>
               <div className="col-sm-10">
                <Input
                 type="select"
                 className="form-control"
                 placeholder="Ingresa tu nivel de formación"
                 id="scholarship"
                 value={this.state.scholarship}
                 onChange={this.handleChange}>
                 <option>N/A</option>
                 <option>Tercero</option>
                 <option>Cuarto</option>
                 <option>Quinto</option>
                 <option>Sexto</option>
                 <option>Séptimo</option>
                 <option>Octavo</option>
                 <option>Noveno</option>
                 <option>Décimo</option>
                 {/* <option>Once</option> */}
                 <option>Bachiller</option>
                 <option>Técnico</option>
                 <option>Tecnólogo</option>
                 <option>Profesional</option>
                 <option>Especialización</option>
                 <option>Maestria</option>
                 <option>Doctorado</option>
                </Input>
               </div>
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">
                Formación en ? *
               </label>
               <div className="col-sm-10">
                <input
                 type="text"
                 className="form-control"
                 placeholder="Ingresa el nombre de la técnica, tecnologia o carrera profesional"
                 id="career"
                 value={this.state.career}
                 onChange={this.handleChange}
                />
               </div>
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">
                Estudia actualmente?
               </label>
               <div className="col-sm-10">
                <Input
                 type="select"
                 className="form-control"
                 placeholder="Ingresa tu nivel de formación"
                 id="currentlyStudying"
                 value={this.state.currentlyStudying}
                 onChange={this.handleChange}>
                 <option value="false">No</option>
                 <option value="true">Si</option>
                </Input>
               </div>
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">Que estudia?</label>
               <div className="col-sm-10">
                <Input
                 type="text"
                 className="form-control"
                 placeholder="Nombre de lo que estudia"
                 id="whatStudies"
                 value={this.state.whatStudies}
                 onChange={this.handleChange}
                />
               </div>
              </div>
             </Col>
             <Col
              xs="12"
              md="6">
              <div className="form-group row">
               <label className="col-12 col-form-label">Ciudad</label>
               <div className="col-sm-10">
                <select
                 className="form-control"
                 id="city"
                 value={this.state.city}
                 onChange={this.handleChange}>
                 <option
                  value=""
                  selected
                  disabled>
                  Seleccione tu ciudad
                 </option>
                 {this.props.citiesData.length != 0
                  ? this.props.citiesData.results.map((item, index) => {
                     return (
                      <option
                       key={index.toString()}
                       value={item.url}>
                       {item.name}
                      </option>
                     );
                    })
                  : null}
                </select>
               </div>
              </div>
              <div className="form-group row">
               <label className="col-12 col-form-label">Dirección</label>
               <div className="col-sm-10">
                <input
                 type="text"
                 className="form-control"
                 placeholder="Ingresa tu dirección"
                 id="direction"
                 value={this.state.direction}
                 onChange={this.handleChange}
                />
               </div>
              </div>
              <div className="form-group row">
               <label className="col-12 col-form-label">Barrio</label>
               <div className="col-sm-10">
                <input
                 type="text"
                 className="form-control"
                 placeholder="Ingresa tu barrio"
                 id="neighborhood"
                 value={this.state.neighborhood}
                 onChange={this.handleChange}
                />
               </div>
              </div>
              <div className="form-group row">
               <label className="col-12 col-form-label">Sector</label>
               <div className="col-sm-10">
                <input
                 type="text"
                 className="form-control"
                 placeholder="Sector / Comuna / Localidad"
                 id="commune"
                 value={this.state.commune}
                 onChange={this.handleChange}
                />
               </div>
              </div>
              <div className="form-group row">
               <label className="col-12 col-form-label">
                Correo electrónico
               </label>
               <div className="col-sm-10">
                <input
                 type="text"
                 className="form-control"
                 placeholder="Ingresa tu correo electronico"
                 id="email"
                 value={this.state.email}
                 onChange={this.handleChange}
                />
               </div>
              </div>
              <div className="form-group row">
               <label className="col-12 col-form-label">Fecha Nacimiento</label>
               <div className="col-sm-10">
                <input
                 type="date"
                 required
                 pattern="[0-9]{2}/[0-9]{2}/[0-9]{4}"
                 className="form-control"
                 placeholder=""
                 id="birthDate"
                 value={this.state.birthDate}
                 onChange={this.handleChange}
                />
               </div>
              </div>
              <div className="form-group row">
               <label className="col-12 col-form-label">Edad</label>
               <div className="col-sm-10">
                <input
                 type="text"
                 className="form-control"
                 placeholder=""
                 id="age"
                 value={this.state.age}
                 disabled
                 onChange={this.handleChange}
                />
               </div>
              </div>
              <div className="form-group row">
               <label className="col-12 col-form-label">Agencia Empleo</label>
               <div className="col-sm-10">
                <select
                 className="form-control"
                 id={this.state.whoIs === 0 ? "idProgram" : "program"}
                 onChange={this.handleChange}
                 value={
                  this.state.whoIs === 0
                   ? this.state.idProgram
                   : this.state.program
                 }>
                 <option
                  value=""
                  selected>
                  Seleccione una agencia
                 </option>
                 <option value={null}>No aplica</option>
                 {this.props.agencies.length != 0
                  ? this.props.agencies.results.map((item, index) => {
                     return (
                      <option
                       key={index.toString()}
                       value={item.url}>
                       {item.fullName}
                      </option>
                     );
                    })
                  : null}
                </select>
               </div>
              </div>
              <div className="form-group row">
               <label className="col-12 col-form-label">Programa</label>
               <div className="col-sm-10">
                <select
                 className="form-control"
                 id={this.state.whoIs === 0 ? "idProgram" : "program"}
                 onChange={this.handleChange}
                 value={
                  this.state.whoIs === 0
                   ? this.state.idProgram
                   : this.state.program
                 }>
                 <option
                  value=""
                  selected>
                  Seleccione un programa
                 </option>
                 {/* <option value={null}>No pertenece</option> */}
                 {this.props.programs.length !== 0
                  ? this.props.programs.results.map((item, index) => {
                     return (
                      <option
                       key={index.toString()}
                       value={item.url}>
                       {item.fullName}
                      </option>
                     );
                    })
                  : null}
                </select>
               </div>
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">
                Tipo de población
               </label>
               <div className="col-sm-10">
                <Input
                 type="select"
                 className="form-contol"
                 id="arnPoblation"
                 onChange={this.handleChange}
                 value={this.state.arnPoblation}>
                 <option>S/I</option>
                 <option>Victima</option>
                 <option>Familiar</option>
                 <option>Justicia y Paz</option>
                 <option>Reincorporación</option>
                 <option>Reintegración</option>
                 <option>Pospenados</option>
                 <option>Migrantes</option>
                </Input>
               </div>
              </div>
              <div className="form-group row">
               <label className="col-sm-12 col-form-label">Estado</label>
               <div className="col-sm-10">
                <Input
                 type="select"
                 className="form-contol"
                 id="arnStatus"
                 onChange={this.handleChange}
                 value={this.state.arnStatus}>
                 <option>N/A</option>
                 <option>S/I</option>
                 <option>Activo</option>
                 <option>Retirado</option>
                 <option>Activo-Reintegro</option>
                 <option>Activo-Aprendiz</option>
                </Input>
               </div>
              </div>
             </Col>
            </Row>
           </TabPane>
           <TabPane tabId="3">
            <br />
            {/* <div className="form-group row">
                          <label className="col-12 col-form-label">Presentacion Personal</label>
                          <div className="col-sm-10">
                            <select
                              className="form-control"
                              id="slcPersonalPresentation"
                              value={this.state.slcPersonalPresentation}
                              disabled
                              onChange={this.handleSelect}
                            >
                              <option value="" selected disabled>Seleccione una opcion</option>
                              <option value="cumple">Cumple</option>
                              <option value="no">No Cumple</option>
                            </select>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-12 col-form-label">Manejo Emocional</label>
                          <div className="col-sm-10">
                            <select
                              className="form-control"
                              id="slcEmotionalManagement"
                              disabled
                              value={this.state.slcEmotionalManagement}
                              onChange={this.handleSelect2}
                            >
                              <option value="" selected disabled>Seleccione una opcion</option>
                              <option value="cumple">Cumple</option>
                              <option value="no">No Cumple</option>
                            </select>
                          </div>
                        </div> */}
            <div className="form-group row">
             <label className="col-12 col-form-label">
              Hallazgos Juridicos
             </label>
             <div className="col-sm-10">
              <select
               className="form-control"
               id="slcFindings"
               onChange={this.handleChange}
               value={this.state.findingsContent}>
               <option value="no">No</option>
               <option value="si">Si</option>
              </select>
             </div>
            </div>
            <div className="form-group row">
             <div className="col-sm-12">Conceptos</div>
             <div className="col-sm-10">
              <textarea
               type="text"
               className="form-control"
               placeholder="Ingrese breve descripción, hallazgos o información relevante."
               onChange={this.handleChange}
               rows="3"
               value={this.state.txtFindingsContent}
               id="txtFindingsContent"></textarea>
             </div>
            </div>
           </TabPane>
           <TabPane tabId="4">
            <br />
            <Row>
             <Col
              xs="12"
              md="6">
              <label
               className="col-12"
               htmlFor="exampleFormControlFile1">
               Lista de documentos
              </label>
              {this.state.documentationFiles.map((item, index) => {
               return (
                <div
                 className="form-group row containerArchivos"
                 key={index.toString()}>
                 <div className="col-sm-10 containerFileDocuments">
                  <div className="row contentDocumentos">
                   <div className="col-sm-6">
                    <select
                     className="form-control"
                     id="documentArr"
                     value={item.docName}
                     disabled
                     onChange={(e) =>
                      this.handleChangePositionsObj(e, "docName", index)
                     }>
                     <option
                      value=""
                      disabled
                      selected>
                      Selecciona archivo
                     </option>
                     <option value="ncedula">Cédula</option>
                     <option value="hojaVida">Hoja de vida</option>
                     <option value="Wartegg">Wartegg</option>
                     <option value="psicometrica">Prueba Psicometrica</option>
                     <option value="prueba">Prueba de lectoescritura</option>
                     <option value="consentimiento">
                      Consentimiento informado
                     </option>
                     <option value="consentimiento">Datos personales</option>
                     <option value="policía">Policía</option>
                     <option value="Procuraduría">Procuraduría</option>
                     <option value="Contraloría">Contraloría</option>
                     <option value="Validación institución aliada">
                      Validación institución aliada
                     </option>
                     <option value="otro">Otro</option>
                    </select>
                   </div>

                   <div className="col-sm-6">
                    <a
                     href={item.url}
                     target="blank"
                     style={{ color: "#000" }}>
                     Ver documento
                    </a>
                   </div>
                  </div>
                 </div>
                </div>
               );
              })}
             </Col>
             <Col
              xs="12"
              md="6">
              <div
               className="row containerPlus"
               onClick={() => this.addDocuments()}>
               <label
                className="col-10"
                htmlFor="exampleFormControlFile1">
                Carga de documentos
               </label>
               <Button
                type="button"
                color="info"
                className="addDoc">
                +
               </Button>
              </div>
              {this.state.documentArr.map((item, index) => {
               return (
                <div
                 className="form-group row containerArchivos"
                 key={index.toString()}>
                 <div className="col-sm-10 containerFileDocuments">
                  <div
                   className="contentCloseDocuments"
                   onClick={() => this.rmDocuments(item, index)}>
                   <a className="btnPlus">
                    <img
                     src={require("../../../assets/Icons/close.png")}
                     alt="Eliminar"
                     className="iconPlus"
                    />
                   </a>
                  </div>
                  <div className="row contentDocumentos">
                   <div className="col-sm-6">
                    <select
                     className="form-control"
                     id="documentArr"
                     value={item.docName}
                     onChange={(e) =>
                      this.handleChangePositionsObj(e, "docName", index)
                     }>
                     <option
                      value=""
                      disabled
                      selected>
                      Selecciona archivo
                     </option>
                     <option value="ncedula">Cédula</option>
                     <option value="hojaVida">Hoja de vida</option>
                     <option value="Wartegg">Wartegg</option>
                     <option value="psicometrica">Prueba Psicometrica</option>
                     <option value="prueba">Prueba de lectoescritura</option>
                     <option value="consentimiento">
                      Consentimiento informado
                     </option>
                     <option value="consentimiento">Datos personales</option>
                     <option value="policía">Policía</option>
                     <option value="Procuraduría">Procuraduría</option>
                     <option value="Contraloría">Contraloría</option>
                     <option value="Validación institución aliada">
                      Validación institución aliada
                     </option>
                     <option value="otro">Otro</option>
                    </select>
                   </div>

                   {/* <div className="col-sm-6">
                                                      <a href={item.url} target="blank" style={{color: '#000'}}>Ver documento</a>
                                                  </div> */}
                   <div className="col-sm-6">
                    <input
                     type="file"
                     className="form-control-file"
                     id="exampleFormControlFile1"
                     onChange={(e) =>
                      this.handleChangeMultiFile(e, "path", index)
                     }
                    />
                   </div>
                  </div>
                 </div>
                </div>
               );
              })}
             </Col>
            </Row>
           </TabPane>
           <TabPane tabId="5">
            <br />
            <Row style={{ width: "100%" }}>
             <Col
              xs="12"
              md="6">
              <div className="form-group row">
               <label
                className="col-12"
                htmlFor="exampleFormControlTextarea1">
                Hallazgo / Motivo
               </label>
               <div className="col-sm-10">
                <SelectSearch
                 options={this.state.optionsFindings}
                 placeholder="Seleccionar hallazgo / motivo"
                 id="findingsAlianza"
                 onChange={this.handleChangeGroup}
                 value={this.state.findingsAlianza}
                />
               </div>
              </div>
              <div className="form-group row">
               <label
                className="col-12"
                htmlFor="exampleFormControlTextarea1">
                Descripción completa
               </label>
               <div className="col-sm-10">
                <Input
                 type="textarea"
                 className="form-control"
                 rows="5"
                 id="findingsAlianzaConcepts"
                 onChange={this.handleChange}
                 value={this.state.findingsAlianzaConcepts}
                />
               </div>
              </div>
              <Col
               xs="12"
               md="12">
               <div className="form-group row">
                <div
                 className="col-12"
                 style={{
                  display: "flex",
                  justifyContent: "flex-end",
                 }}>
                 <button
                  className="btn btn-info"
                  type="button"
                  onClick={this.addFinding}>
                  Agregar
                 </button>
                </div>
               </div>
              </Col>
             </Col>
             {this.props.findings.length !== 0
              ? this.props.findings.results.map((item, index) => (
                 <Col
                  xs="12"
                  md="6"
                  key={index.toString()}>
                  <div className="form-group row">
                   <label
                    className="col-12"
                    htmlFor="exampleFormControlTextarea1">
                    Hallazgo / Motivo
                   </label>
                   <div className="col-sm-10">
                    <SelectSearch
                     options={this.state.optionsFindings}
                     placeholder="Seleccionar hallazgo / motivo"
                     disabled
                     value={item.findingType}
                    />
                   </div>
                  </div>
                  <div className="form-group row">
                   <label
                    className="col-12"
                    htmlFor="exampleFormControlTextarea1">
                    Descripción completa
                   </label>
                   <div className="col-sm-10">
                    <Input
                     type="textarea"
                     className="form-control"
                     rows="5"
                     disabled
                     value={item.reasonFindings}
                    />
                   </div>
                  </div>
                 </Col>
                ))
              : null}
            </Row>
           </TabPane>
           <TabPane tabId="6">
            <br />
            <Row>
             <Col
              xs="12"
              md="6">
              <div className="form-group row">
               <label
                className="col-12"
                htmlFor="exampleFormControlTextarea1">
                Contesto
               </label>
               <div className="col-sm-10">
                <select
                 className="form-control"
                 id="candidateAnswerInterview"
                 onChange={this.handleChange}
                 value={this.state.candidateAnswerInterview}>
                 <option value="no">No</option>
                 <option value="si">Si</option>
                </select>
               </div>
              </div>
              <div className="form-group row">
               <label
                className="col-12"
                htmlFor="exampleFormControlTextarea1">
                Entrevistador
               </label>
               <div className="col-sm-10">
                <Input
                 type="select"
                 className="form-control"
                 onChange={this.handleChange}
                 id="interviewer"
                 value={this.state.interviewer}>
                 <option
                  value=""
                  disabled>
                  Selecciona el entrevistador
                 </option>
                 {this.props.managersList.length !== 0
                  ? this.props.managersList.results.map(
                     (item, index) =>
                      item.id !== 5 &&
                      item.id !== 4 && (
                       <option key={index.toString()}>{item.fullName}</option>
                      )
                    )
                  : null}
                </Input>
               </div>
              </div>
              <div className="form-group row">
               <label
                className="col-12"
                htmlFor="exampleFormControlTextarea1">
                Fecha de la entrevista
               </label>
               <div className="col-sm-10">
                <input
                 type="date"
                 className="form-control"
                 id="dateInterview"
                 onChange={this.handleChange}
                 value={this.state.dateInterview}
                />
               </div>
              </div>
              <div className="form-group row">
               <label
                className="col-12"
                htmlFor="exampleFormControlTextarea1">
                Hora de la entrevista
               </label>
               <div className="col-sm-10">
                <Input
                 type="time"
                 className="form-control"
                 id="hourInterview"
                 onChange={this.handleChange}
                 value={this.state.hourInterview}
                />
               </div>
              </div>
             </Col>
             <Col
              xs="12"
              md="6">
              <div
               className="form-group row"
               style={{ display: "none" }}>
               <label
                className="col-12"
                htmlFor="exampleFormControlTextarea1">
                Tiene tatuajes visibles
               </label>
               <div className="col-sm-10">
                <select
                 className="form-control"
                 id="tattoInterview"
                 onChange={this.handleChange}
                 value={this.state.tattoInterview}>
                 <option value="no">No</option>
                 <option value="si">Si</option>
                </select>
               </div>
              </div>
              <div className="form-group row">
               <label
                className="col-12"
                htmlFor="exampleFormControlTextarea1">
                Conceptos
               </label>
               <div className="col-sm-10">
                <textarea
                 className="form-control"
                 rows="3"
                 id="concepts"
                 onChange={this.handleChange}
                 value={this.state.concepts}
                 rows={8}></textarea>
               </div>
              </div>
             </Col>
            </Row>
           </TabPane>
           <TabPane tabId="7">
            <br />
            <Row>
             <Col
              xs="12"
              md="6">
              <div className="form-group row">
               <label
                className="col-12"
                htmlFor="exampleFormControlTextarea1">
                Fecha de la entrevista Contratacion
               </label>
               <div className="col-sm-10">
                <input
                 type="date"
                 className="form-control"
                 id="dateInterviewC"
                 onChange={this.handleChange}
                 value={this.state.dateInterviewC}
                 disabled
                />
               </div>
              </div>

              <div className="form-group row">
               <label
                className="col-12"
                htmlFor="exampleFormControlTextarea1">
                Hora de la entrevista Contratacion
               </label>
               <div className="col-sm-10">
                <Input
                 type="time"
                 className="form-control"
                 id="hourInterviewC"
                 onChange={this.handleChange}
                 value={this.state.hourInterviewC}
                 disabled
                />
               </div>
              </div>

              <div className="form-group row">
               <label
                className="col-12"
                htmlFor="exampleFormControlTextarea1">
                Detalles entrevista Contratacion
               </label>
               <div className="col-sm-10">
                <textarea
                 className="form-control"
                 rows="3"
                 id="conceptsHa"
                 onChange={this.handleChange}
                 value={this.state.conceptsHa}></textarea>
               </div>
              </div>

              <hr></hr>

              {/* <div className="form-group row">
               <label
                className="col-12"
                htmlFor="medicalExam">
                Examenes médicos
               </label>
               <div className="col-sm-10">
                <select
                 className="form-control"
                 id="medicalExam"
                 onChange={this.handleChange}
                 value={this.state.medicalExam}>
                 <option value="no">No</option>
                 <option value="si">Si</option>
                </select>
               </div>
              </div> */}

              <div className="form-group row">
               <label
                className="col-12"
                htmlFor="induction">
                Inducción
               </label>
               <div className="col-sm-10">
                <select
                 className="form-control"
                 id="induction"
                 onChange={this.handleChange}
                 value={this.state.induction}>
                 <option value="no">No</option>
                 <option value="si">Si</option>
                </select>
               </div>
              </div>
              {/*  <div className="form-group row">
               <label
                className="col-12"
                htmlFor="induction">
                Observaciones Examenes Medicos
               </label>
               <div className="col-sm-10">
                <textarea
                 className="form-control"
                 rows="3"
                 id="conceptsHa2"></textarea>
               </div>
              </div> */}

              {/*   <div
                              className="form-group row"
                              style={{ display: "none" }}
                            >
                              <label className="col-12" htmlFor="references">
                                Cartas de referencia
                              </label>
                              <div className="col-sm-10">
                                <select
                                  className="form-control"
                                  id="references"
                                  onChange={this.handleChange}
                                  value={this.state.references}
                                >
                                  <option value="no">No</option>
                                  <option value="si">Si</option>
                                </select>
                              </div>
                            </div>

                            <div
                              className="form-group row"
                              style={{ display: "none" }}
                            >
                              <label className="col-12" htmlFor="courses">
                                Cursos realizados
                              </label>
                              <div className="col-sm-10">
                                <select
                                  className="form-control"
                                  id="courses"
                                  onChange={this.handleChange}
                                  value={this.state.courses}
                                >
                                  <option value="no">No</option>
                                  <option value="si">Si</option>
                                </select>
                              </div>
                            </div> */}

              <hr></hr>

              <div className="form-group row">
               <label
                className="col-12"
                htmlFor="vinculateCompany">
                Cliente
               </label>
               <div className="col-sm-10">
                <select
                 className="form-control"
                 id="vinculateCompany"
                 onChange={this.handleChange}
                 value={this.state.vinculateCompany}
                 disabled>
                 <option
                  value=""
                  disabled>
                  Seleccione un cliente
                 </option>
                 {this.props.clientData.length != 0
                  ? this.props.clientData.results.map((item, index) => {
                     return (
                      <option
                       key={index.toString()}
                       value={item.url}>
                       {item.fullName}
                      </option>
                     );
                    })
                  : null}
                </select>
               </div>
              </div>

              <div className="form-group row">
               <label
                className="col-12"
                htmlFor="nameBussiness">
                Negocio
               </label>
               <div className="col-sm-10">
                <select
                 className="form-control"
                 id="nameBussiness"
                 onChange={this.handleChange}
                 value={this.state.nameBussiness}
                 disabled>
                 <option value="bancolombia">Bancolombia</option>
                </select>
               </div>
              </div>
             </Col>
             <Col
              xs="12"
              md="6">
              <div className="form-group row">
               <label
                className="col-12"
                htmlFor="contractDate">
                Fecha de contrato
               </label>
               <div className="col-sm-10">
                <input
                 type="date"
                 className="form-control"
                 id="contractDate"
                 onChange={this.handleChange}
                 value={this.state.contractDate}
                />
               </div>
              </div>

              <div className="form-group row">
               <label
                className="col-12"
                htmlFor="contractType">
                Tipo de contrato
               </label>
               <div className="col-sm-10">
                <select
                 className="form-control"
                 id="contractType"
                 onChange={this.handleChange}
                 value={this.state.contractType}>
                 <option value="fijo">Fijo</option>
                 <option value="indefinido">Indefinido</option>
                 <option value="temoporal">Por temporal</option>
                 <option value="prestacion">Prestación de servicios</option>
                 <option value="aprendizaje">Contrato de aprendizaje</option>
                </select>
               </div>
              </div>

              <div className="form-group row">
               <label
                className="col-12"
                htmlFor="inmediateBoss">
                Cuota cedida?
               </label>
               <div className="col-sm-2">
                <select
                 className="form-control"
                 id="wasSent"
                 onChange={this.handleChange}
                 value={this.state.wasSent}>
                 <option value="no">No</option>
                 <option value="si">Si</option>
                </select>
               </div>
               <div className="col-sm-8">
                <input
                 type="text"
                 className="form-control"
                 id="place"
                 onChange={this.handleChange}
                 value={this.state.place}
                 disabled={this.state.wasSent === "no" ? true : false}
                />
               </div>
              </div>

              <div className="form-group row">
               <label
                className="col-12"
                htmlFor="immediateBoss">
                Jefe inmediato
               </label>
               <div className="col-sm-10">
                <input
                 type="text"
                 className="form-control"
                 id="immediateBoss"
                 onChange={this.handleChange}
                 value={this.state.immediateBoss}
                />
               </div>
              </div>

              <div className="form-group row">
               <label
                className="col-12"
                htmlFor="exampleFormControlTextarea1">
                Grupo
               </label>
               <div className="col-sm-10">
                <select
                 className="form-control"
                 id="group"
                 onChange={this.handleChange}
                 value={this.state.group}>
                 <option defaultValue="">Selecciona el grupo</option>
                 <option value="N/A">No Aplica</option>
                 <option value="Basico">Básico</option>
                 <option value="Basico A">Básico A</option>
                 <option value="Basico B">Básico B</option>
                 <option value="Basico C">Básico C</option>
                 <option value="Avanzado">Avanzado</option>
                 <option value="Graduados">Graduados</option>
                 <option value="Aprendices">Aprendices</option>
                 <option value="Grupo A">Grupo A</option>
                 <option value="Grupo B">Grupo B</option>
                 <option value="Otras">Otras ciudades</option>
                </select>
               </div>
              </div>
             </Col>
            </Row>
           </TabPane>
           <TabPane tabId="8">
            <br />
            <Row>
             <Col
              xs="12"
              md="12">
              <div className="form-group row">
               <div
                className="col-12"
                style={{
                 display: "flex",
                 justifyContent: "space-between",
                }}>
                <h6>Historial Asesorías</h6>
                <button
                 className="btn btn-info"
                 type="button"
                 onClick={this.newAdvisory}>
                 Nueva asesoría
                </button>
               </div>
              </div>
             </Col>
             {this.props.consultanciesData.length !== 0
              ? this.props.consultanciesData.results.map((item) => {
                 return (
                  <Col
                   xs="12"
                   md="6">
                   <div
                    className="form-group row"
                    style={{ paddingLeft: 15 }}>
                    <p>
                     <b>Tema:</b> {item.theme}
                    </p>
                    <p>
                     <b>Fecha:</b> {item.advisoryDate}
                    </p>
                    <p>
                     <b>Duración:</b> {item.duration} min
                    </p>
                   </div>
                   <hr></hr>
                  </Col>
                 );
                })
              : null}
            </Row>
           </TabPane>
           <TabPane tabId="9">
            <br />
            <Row>
             {this.renderTracingModal()}
             <Col
              xs="12"
              md="12">
              <div className="form-group row">
               <div
                className="col-12"
                style={{
                 display: "flex",
                 justifyContent: "space-between",
                }}>
                <h6>Historial Seguimientos antes del 2023</h6>
                <FormGroup>
                 <select
                  className="form-control"
                  name="selectYear"
                  id="selectYear"
                  onChange={(e) => {
                   this.filterByYear(e);
                  }}>
                  {this.state.years.map((year, index) => (
                   <option key={index.toString()}>{year}</option>
                  ))}
                 </select>
                </FormGroup>
               </div>
              </div>
             </Col>
             {this.props.candidateTracing.length !== 0
              ? this.props.candidateTracing.results.map((item, index) => {
                 return (
                  <Col
                   xs="12"
                   md="6"
                   key={index.toString()}>
                   <div
                    className="form-group row"
                    style={{ paddingLeft: 15 }}>
                    <ul className="timeline">
                     <li>
                      <p>
                       <b>Año:</b> {item.year}
                      </p>
                      <p>
                       <b>Mes:</b> {item.month}
                      </p>
                      <p>
                       <b>Negocio:</b>{" "}
                       {item.candidate.positions[0].nameBussiness}
                      </p>
                      <div
                       onClick={() => this.toggleModal(item)}
                       className={`segRanking bgColor${
                        item.total <= 6
                         ? "0"
                         : item.total > 6 && item.total <= 12
                         ? "1"
                         : item.total > 12 && item.total <= 14
                         ? "2"
                         : item.total > 14 && item.total <= 16
                         ? "3"
                         : "4"
                       }`}>
                       <span>Puntaje: {item.total}</span>
                      </div>
                     </li>
                    </ul>
                   </div>
                   <hr></hr>
                  </Col>
                 );
                })
              : null}
            </Row>
            <Row>
             {this.renderTracingNuevoModal()}
             <Col
              xs="12"
              md="12">
              <div className="form-group row">
               <div
                className="col-12"
                style={{
                 display: "flex",
                 justifyContent: "space-between",
                }}>
                <h6>Historial Seguimientos a partir 2023</h6>
                <Link
                 to={`/seleccion/acompanamiento/seguimiento-nuevo/0/25/04/seguimiento/new/${this.props.match.params.candidate}`}>
                 <i
                  className="fa fa-plus-circle"
                  style={{ color: "#28a745" }}
                  aria-hidden="true"></i>{" "}
                 Nuevo seguimiento
                </Link>
               </div>
              </div>
             </Col>
             {this.props.candidateTracingNuevo.length !== 0
              ? this.props.candidateTracingNuevo.results.map((item, index) => {
                 return (
                  <Col
                   xs="12"
                   md="6"
                   key={index.toString()}>
                   <div
                    className="form-group row"
                    style={{ paddingLeft: 15 }}>
                    <ul className="timeline">
                     <li>
                      <p>
                       <b>Año:</b> {item.year}
                      </p>
                      <p>
                       <b>Mes:</b> {item.month}
                      </p>
                      <p>
                       <b>Negocio:</b>{" "}
                       {item.candidate.positions[0].nameBussiness}
                      </p>
                      <div
                       onClick={() => this.toggleModal(item)}
                       className={`segRanking bgColor${
                        item.total <= 6
                         ? "0"
                         : item.total > 6 && item.total <= 12
                         ? "1"
                         : item.total > 12 && item.total <= 14
                         ? "2"
                         : item.total > 14 && item.total <= 16
                         ? "3"
                         : "4"
                       }`}>
                       <span>Puntaje: {item.total}</span>
                      </div>
                     </li>
                    </ul>
                   </div>
                   <hr></hr>
                  </Col>
                 );
                })
              : null}
            </Row>
           </TabPane>
           <TabPane tabId="10">
            <br />
            <Row>
             <Col
              xs="12"
              md="12">
              <div className="form-group row">
               <div
                className="col-12"
                style={{
                 display: "flex",
                 justifyContent: "space-between",
                }}>
                <h6>Historial Talleres</h6>
                <button
                 className="btn btn-info"
                 type="button"
                 onClick={this.newWorkshop}>
                 Nuevo taller
                </button>
               </div>
              </div>
             </Col>
             {this.props.workshopsUser.length !== 0
              ? this.props.workshopsUser.results.map((item) => {
                 // console.log(item);
                 // console.log(this.state.assistance);
                 var newAssistance = this.state.assistance.filter((el) => {
                  return el.workshop === item.url;
                 });
                 // console.log(newAssistance);
                 return (
                  <Col
                   xs="12"
                   md="6">
                   <div
                    className="form-group row customP"
                    style={{
                     paddingLeft: 15,
                     fontWeight: 200,
                    }}>
                    <p>
                     <b>Fecha:</b> {item.date} - {item.hour}
                    </p>
                    <p>
                     <b>Temática:</b> {item.thematic}
                    </p>
                    <p>
                     <b>Lugar:</b> {item.place}
                    </p>
                    {newAssistance.length > 0 && (
                     <>
                      <p>
                       <b>Asistencia:</b> {newAssistance[0].reason}
                      </p>
                      <p>
                       <b>Observaciones:</b> {newAssistance[0].notes}
                      </p>
                     </>
                    )}
                   </div>
                   <hr></hr>
                  </Col>
                 );
                })
              : null}
            </Row>
           </TabPane>
           <TabPane tabId="11">
            <br />
            <Row>
             <Col
              xs="12"
              md="12">
              <h6>Historial procesos</h6>
              <div
               className="form-group row"
               style={{ paddingLeft: 15 }}>
               <ul className="timeline">
                {this.props.traceability.length !== 0
                 ? this.props.traceability.results.map((item, index) => {
                    return (
                     <li key={index.toString()}>
                      <p>{`${item.action} el ${
                       item.timestamp.split("T")[0]
                      }`}</p>
                     </li>
                    );
                   })
                 : null}
               </ul>
              </div>
             </Col>
            </Row>
           </TabPane>
           <TabPane tabId="12">
            <br />
            <Row>
             <Col>
              <div className="form-group row">
               <label
                className="col-12"
                htmlFor="retirementDate">
                Fecha de retiro
               </label>
               <div className="col-sm-10">
                <input
                 type="date"
                 className="form-control"
                 id="retirementDate"
                 onChange={this.handleChange}
                 value={this.state.retirementDate}
                />
               </div>
              </div>
              <div className="form-group row">
               <label
                className="col-12"
                htmlFor="reason">
                Motivo
               </label>
               <div className="col-sm-10">
                <select
                 type="type"
                 className="form-control"
                 id="reason"
                 onChange={this.handleChange}
                 value={this.state.reason}>
                 <option
                  value=""
                  disabled>
                  Selecciona el motivo
                 </option>
                 <option value="Finalizacion">Finalización de formación</option>
                 <option value="Abandono">Abandono de trabajo</option>
                 <option value="Muerte">Muerte</option>
                 <option value="Renuncia Voluntaria">
                  Renuncia Voluntaria
                 </option>
                 <option value="Terminación de Contrato">
                  Terminación de Contrato
                 </option>
                </select>
               </div>
              </div>
              <div className="form-group row">
               <label
                className="col-12"
                htmlFor="reasonDetail">
                Descripción motivo
               </label>
               <div className="col-sm-10">
                <textarea
                 className="form-control"
                 id="reasonDetail"
                 onChange={this.handleChange}
                 value={this.state.reasonDetail}
                 rows={7}></textarea>
               </div>
              </div>
             </Col>
            </Row>
           </TabPane>
           <TabPane tabId="13">
            <br />

            <div className="form-group row">
             <label className="col-sm-2 col-form-label">Estado</label>
             <div className="col-sm-6">
              <select
               className="form-control"
               id="pruebas_estado"
               onChange={this.handleChange}>
               <option value="revision">revisión</option>
               <option value="aprobado">aprobado</option>
               <option value="noaprobado">no aprobado</option>
              </select>
             </div>
            </div>

            <div className="form-group row">
             <label
              className="col-sm-2"
              htmlFor="pruebas_sinopsis">
              Sinopsis
             </label>
             <div className="col-sm-6">
              <textarea
               className="form-control"
               id="pruebas_sinopsis"
               onChange={this.handleChange}
               placeholder="Ingrese breve descripción, hallazgos o información relevante."
               rows="3"></textarea>
             </div>
            </div>
           </TabPane>
          </TabContent>
         </Form>
        </CardBody>
        <CardFooter>
         <div className="col-12 containerBtnEnviar">
          <button
           type="button"
           className="btn-success btnEnviar"
           onClick={() => this.updateDataCandidate()}>
           ACTUALIZAR
          </button>
          <button
           type="button"
           className="btn-success btnEnviar">
           DESCARGAR
          </button>
         </div>
        </CardFooter>
       </Card>
      </Col>
     </Row>
    </div>
   );
  } else {
   return (
    <div className="backgroundHojaVida">
     <Row style={{ width: "100%", alignItems: "center", marginTop: "20%" }}>
      <Spinner
       type="grow"
       size="sm"
       color="info"
      />{" "}
      <Spinner
       type="grow"
       color="info"
      />{" "}
      <Spinner
       type="grow"
       size="sm"
       color="info"
      />{" "}
     </Row>
    </div>
   );
  }
 }
}

const mapStateToProps = (state) => {
 return {
  visibleTabs: state.candidateReducer.visibleTabs,
  isLoading: state.candidateReducer.isLoading,
  candidate: state.candidateReducer.candidate,
  candidates: state.candidateReducer.candidates,
  programs: state.candidateReducer.programs,
  agencies: state.candidateReducer.agencies,
  interviewByID: state.selectionReducer.interviewByID,
  isSaveCandidate: state.candidateReducer.isSaveCandidate,
  citiesData: state.citiesReducer.citiesData,
  allAllys: state.allyReducer.allAllys,
  clientData: state.clientsReducer.clientData,
  allyData: state.allyReducer.allyData,
  vacanteData2: state.vacanteReducer.vacanteData2,
  modulePath: state.allyReducer.modulePath,
  interviewList: state.interviewReducer.interviewList,
  consultanciesData: state.consultanciesReducer.consultanciesData,
  candidateTracing: state.accompanimentReducer.candidateTracing,
  candidateTracingNuevo: state.accompanimentReducer.candidateTracingNuevo,
  workshopsUser: state.accompanimentReducer.workshopsUser,
  managersList: state.managersReducer.managersList,
  findings: state.selectionReducer.findings,
  traceability: state.traceabilityReducer.traceability,
 };
};

const mapDispatchToProps = (dispatch) => {
 return {
  getCandidateByID: bindActionCreators(getCandidateByID, dispatch),
  getAllInterviewByID: bindActionCreators(getAllInterviewByID, dispatch),
  update_Candidate: bindActionCreators(update_Candidate, dispatch),
  add_Candidate: bindActionCreators(add_Candidate, dispatch),
  getAllCities: bindActionCreators(getAllCities, dispatch),
  getAllAllys: bindActionCreators(getAllAllys, dispatch),
  getAllClient: bindActionCreators(getAllClient, dispatch),
  get_all_agencies: bindActionCreators(get_all_agencies, dispatch),
  get_all_programs: bindActionCreators(get_all_programs, dispatch),
  createFinding: bindActionCreators(createFinding, dispatch),
  createInterviewState: bindActionCreators(createInterviewState, dispatch),
  getAllVacantes: bindActionCreators(getAllVacantes, dispatch),
  updatePositionsSubstates: bindActionCreators(
   updatePositionsSubstates,
   dispatch
  ),
  setCandidate: bindActionCreators(setCandidate, dispatch),
  getInterviewsByCandidate: bindActionCreators(
   getInterviewsByCandidate,
   dispatch
  ),
  updateInterviewAnswered: bindActionCreators(
   updateInterviewAnswered,
   dispatch
  ),
  createInterviewAnswered: bindActionCreators(
   createInterviewAnswered,
   dispatch
  ),
  getAllConsultanciesByCandidate: bindActionCreators(
   getAllConsultanciesByCandidate,
   dispatch
  ),
  getTracingCandidate: bindActionCreators(getTracingCandidate, dispatch),
  getTracingCandidateNuevo: bindActionCreators(
   getTracingCandidateNuevo,
   dispatch
  ),
  addInfoContract: bindActionCreators(addInfoContract, dispatch),
  updateInfoContract: bindActionCreators(updateInfoContract, dispatch),
  getWorkshopByUser: bindActionCreators(getWorkshopByUser, dispatch),
  getManagersList: bindActionCreators(getManagersList, dispatch),
  getFindingsCandidate: bindActionCreators(getFindingsCandidate, dispatch),
  addTraceability: bindActionCreators(addTraceability, dispatch),
  getCandidateTrace: bindActionCreators(getCandidateTrace, dispatch),
  setIsSave: bindActionCreators(setIsSave, dispatch),
 };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewHojaVida);
